// App.js
import React from 'react';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Welcome to My Personal Website</h1>
      </header>
      <main>
        <section>
          <h2>About Me</h2>
          <p>This is where you can introduce yourself and talk about your interests, skills, and experiences.</p>
        </section>
        <section>
          <h2>Projects</h2>
          <p>Here you can showcase some of your projects, with links to their repositories or live demos.</p>
        </section>
        <section>
          <h2>Contact</h2>
          <p>Provide ways for visitors to get in touch with you, such as through a contact form or social media links.</p>
        </section>
      </main>
      <footer>
        <p>&copy; 2024 Your Name</p>
      </footer>
    </div>
  );
}

export default App;
